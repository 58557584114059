var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    attrs: {
      "label-size": "sm",
      "label": "x",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.x
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'x',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "y",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.y
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'y',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "fontFamily",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-select', {
    attrs: {
      "size": "sm",
      "value": _vm.config.fontFamily,
      "options": ['Arial', 'Ubuntu', 'Roboto']
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('config', {
          prop: 'fontFamily',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "fontSize",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.fontSize
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'fontSize',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "fontStyle",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-select', {
    attrs: {
      "size": "sm",
      "value": _vm.config.fontStyle,
      "options": ['normal', 'bold', 'italic']
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }