var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": _vm.item !== undefined ? _vm.item.name : undefined
    },
    scopedSlots: _vm._u([{
      key: "breadcrumbs",
      fn: function () {
        return [_c('b-breadcrumb-item', {
          attrs: {
            "to": {
              name: 'ProductionProducts'
            }
          }
        }, [_vm._v(" Productieproducten ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          attrs: {
            "size": "lg",
            "variant": "success"
          },
          on: {
            "click": function ($event) {
              return _vm.save();
            }
          }
        }, [_vm._v(" Opslaan ")])];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_vm.item !== undefined ? _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-input', {
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "2",
      "label": "Methode"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "stacked": "",
      "options": ['digital', 'largeformat', 'offset']
    },
    model: {
      value: _vm.item.method,
      callback: function ($$v) {
        _vm.$set(_vm.item, "method", $$v);
      },
      expression: "item.method"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "2",
      "content-cols": "9",
      "label": "Dubbelzijdig"
    }
  }, [_c('b-form-checkbox', {
    model: {
      value: _vm.item.doubleSided,
      callback: function ($$v) {
        _vm.$set(_vm.item, "doubleSided", $$v);
      },
      expression: "item.doubleSided"
    }
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Materiaal"
    }
  }, [_c('MultiSelect', {
    attrs: {
      "track-by": "@id",
      "label": "name",
      "options": _vm.materials,
      "placeholder": "Typ om te zoeken"
    },
    on: {
      "search-change": _vm.search
    },
    model: {
      value: _vm.itemMaterial,
      callback: function ($$v) {
        _vm.itemMaterial = $$v;
      },
      expression: "itemMaterial"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Breedte"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "mm"
    }
  }, [_c('b-input', {
    attrs: {
      "number": "",
      "type": "number",
      "min": "0"
    },
    model: {
      value: _vm.item.width,
      callback: function ($$v) {
        _vm.$set(_vm.item, "width", $$v);
      },
      expression: "item.width"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hoogte"
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "mm"
    }
  }, [_c('b-input', {
    attrs: {
      "number": "",
      "min": "0",
      "type": "number"
    },
    model: {
      value: _vm.item.height,
      callback: function ($$v) {
        _vm.$set(_vm.item, "height", $$v);
      },
      expression: "item.height"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Schoonzijde"
    }
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "stacked": "",
      "options": ['CMYK', 'Black', 'PMS1', 'PMS2', 'Foil', 'Varnish']
    },
    model: {
      value: _vm.item.colors.front,
      callback: function ($$v) {
        _vm.$set(_vm.item.colors, "front", $$v);
      },
      expression: "item.colors.front"
    }
  })], 1)], 1), _c('b-col', [_vm.item.doubleSided ? _c('b-form-group', {
    attrs: {
      "label": "Weerzijde"
    }
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "stacked": "",
      "options": ['CMYK', 'Black', 'PMS1', 'PMS2', 'Foil', 'Varnish']
    },
    model: {
      value: _vm.item.colors.back,
      callback: function ($$v) {
        _vm.$set(_vm.item.colors, "back", $$v);
      },
      expression: "item.colors.back"
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Levertijd"
    }
  }, [_c('b-form-input', {
    attrs: {
      "number": "",
      "type": "number"
    },
    model: {
      value: _vm.item.deliveryDays,
      callback: function ($$v) {
        _vm.$set(_vm.item, "deliveryDays", $$v);
      },
      expression: "item.deliveryDays"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "border-left",
    attrs: {
      "md": "4"
    }
  }, [_c('b-table-simple', [_c('b-tbody', [_c('b-tr', [_c('b-th', {
    staticClass: "border-top-0"
  }, [_vm._v(" MultiPress ID ")]), _c('b-td', {
    staticClass: "border-top-0"
  }, [_c('code', {
    staticClass: "text-break"
  }, [_vm._v(" " + _vm._s(_vm.item.reference) + " ")])])], 1), _c('b-tr', [_c('b-th', [_vm._v("Actief in MultiPress")]), _c('b-td', [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', _vm.item.active ? 'check' : 'times']
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', [_vm._v("Verrijking compleet")]), _c('b-td', [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', _vm.item.valid ? 'check' : 'times']
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', [_vm._v("Basisprijs")]), _c('b-td', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.item.basePrice, 5)) + " ")])], 1), _c('b-tr', [_c('b-th', [_vm._v("Stuksprijs")]), _c('b-td', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.item.unitPrice, 5)) + " ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }