var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": 'accordeon-' + _vm.production.printingsheet
    }
  }, [_vm.showLabel ? _c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "body-bg-variant": "primary",
      "body-text-variant": "light"
    }
  }, [_c('b-card-title', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.production.printingsheet) + " (" + _vm._s(_vm.production.printingmatter) + ") ")])], 1) : _vm._e(), _c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'method-' + _vm.production.printingsheet,
      expression: "'method-' + production.printingsheet"
    }]
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v("01")]), _c('strong', {
    staticClass: "flex-grow-1"
  }, [_vm._v("Methode")]), _c('span', [_vm._v(" " + _vm._s(_vm.selectedProductionMethod ? _vm.selectedProductionMethod.description : undefined) + " ")])], 1)]), _c('b-collapse', {
    attrs: {
      "id": 'method-' + _vm.production.printingsheet,
      "accordion": '#accordeon-' + _vm.production.printingsheet
    }
  }, [_c('b-card-body', _vm._l(_vm.availableMethods, function (method) {
    return _c('b-btn', {
      key: method,
      staticClass: "mr-2",
      attrs: {
        "variant": _vm.selectedMethod === method ? 'primary' : 'light'
      },
      on: {
        "click": function ($event) {
          _vm.selectedMethod = method;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getProductionMethodDescription(method)) + " ")]);
  }), 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'size-' + _vm.production.printingsheet,
      expression: "'size-' + production.printingsheet"
    }]
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v("02")]), _c('strong', {
    staticClass: "flex-grow-1"
  }, [_vm._v("Afmeting")]), _c('span', [_vm._v(_vm._s(_vm.selectedSize ? _vm.selectedSize.name : null))])], 1)]), _c('b-collapse', {
    attrs: {
      "id": 'size-' + _vm.production.printingsheet,
      "accordion": '#accordeon-' + _vm.production.printingsheet
    }
  }, [_c('b-card-body', [_c('b-radio-group', {
    staticStyle: {
      "columns": "2",
      "column-gap": "1rem"
    },
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.selectedSize,
      callback: function ($$v) {
        _vm.selectedSize = $$v;
      },
      expression: "selectedSize"
    }
  }, _vm._l(_vm.availableSizes, function (item, index) {
    return _c('b-radio', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_c('div', {
      staticClass: "mb-4"
    }, [_c('MaterialBlueprint', {
      attrs: {
        "show-dimensions": true,
        "width": item.width,
        "height": item.height,
        "name": item.name
      }
    })], 1)]);
  }), 1)], 1)], 1)], 1), _vm.selectedMethod ? _c('b-card', {
    staticClass: "mb-3 color-select",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: 'color-' + _vm.production.printingsheet,
      expression: "'color-' + production.printingsheet"
    }]
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary",
      "pill": ""
    }
  }, [_vm._v("03")]), _c('strong', {
    staticClass: "flex-grow-1"
  }, [_vm._v("Bedrukking")]), _c('span', [_vm._v(" " + _vm._s(_vm.selectedColor ? _vm.selectedColor.description : undefined) + " ")])], 1)]), _c('b-collapse', {
    attrs: {
      "id": 'color-' + _vm.production.printingsheet,
      "accordion": '#accordeon-' + _vm.production.printingsheet
    }
  }, [_c('b-card-body', [_c('b-radio-group', {
    staticStyle: {
      "columns": "2",
      "column-gap": "1rem"
    },
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.selectedColor,
      callback: function ($$v) {
        _vm.selectedColor = $$v;
      },
      expression: "selectedColor"
    }
  }, _vm._l(_vm.selectedProductionMethodColors, function (item, index) {
    return _c('b-radio', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_c('div', {
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(item.description) + " ")]), _c('div', {
      staticClass: "sides"
    }, [_c('div', {
      staticClass: "front"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light"
      }
    }, [_vm._v(" Schoonzijde ")]), _c('div', {
      staticClass: "color-list"
    }, _vm._l(_vm.parseColorDetails(item.detail).front, function (color) {
      return _c('font-awesome-icon', {
        key: color,
        staticClass: "mr-1",
        class: 'color-item text-' + _vm.mapColor(color),
        attrs: {
          "icon": "square",
          "title": color
        }
      });
    }), 1)], 1), _vm.parseColorDetails(item.detail).back.length > 0 ? _c('div', {
      staticClass: "back"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light"
      }
    }, [_vm._v(" Weerzijde ")]), _c('div', {
      staticClass: "color-list"
    }, _vm._l(_vm.parseColorDetails(item.detail).back, function (color) {
      return _c('font-awesome-icon', {
        key: color,
        staticClass: "mr-1",
        class: 'color-item text-' + _vm.mapColor(color),
        attrs: {
          "icon": "square",
          "title": color
        }
      });
    }), 1)], 1) : _vm._e()])])]);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.selectedMethod ? _c('material-item', {
    attrs: {
      "method": _vm.production.printingsheet,
      "materials": _vm.selectedProductionMethodMaterials
    }
  }) : _vm._e(), _vm.production.finishing ? _c('b-card', {
    staticClass: "mb-3"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Afwerking"
    }
  }, [_c('b-form-select', _vm._l(_vm.production.finishing, function (item, index) {
    return _c('b-form-select-option', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")]);
  }), 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }