var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [!_vm.editName ? _c('div', [_vm._v(" " + _vm._s(_vm.productionPackage.name) + " "), !_vm.editName ? _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              _vm.editName = !_vm.editName;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'pencil'],
            "fixed-width": ""
          }
        })], 1) : _vm._e()], 1) : _c('b-input', {
          staticClass: "py-0",
          staticStyle: {
            "height": "auto"
          },
          attrs: {
            "placeholder": _vm.packageName,
            "debounce": "500"
          },
          on: {
            "keyup": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              _vm.editName = false;
            }
          },
          model: {
            value: _vm.packageName,
            callback: function ($$v) {
              _vm.packageName = $$v;
            },
            expression: "packageName"
          }
        })], 1), _vm.editName ? _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              _vm.editName = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'check'],
            "fixed-width": ""
          }
        })], 1) : _vm._e(), _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              return _vm.deletePackage();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'trash'],
            "fixed-width": ""
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('transition-group', {
    staticClass: "d-flex flex-wrap my-1 mx-2",
    attrs: {
      "tag": "div",
      "name": "slide-fade"
    }
  }, [_vm._l(_vm.productionPackageItems, function (product) {
    return _c('ProductionProductItem', {
      key: product['@id'],
      attrs: {
        "product-prop": product.relationProductionProduct
      },
      on: {
        "delete": function ($event) {
          return _vm.removeProduct(product);
        }
      }
    });
  }), _c('b-button', {
    key: "static",
    staticClass: "text-muted",
    staticStyle: {
      "width": "190px",
      "height": "212px"
    },
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.showProductModal
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "3x",
      "icon": ['fal', 'plus']
    }
  })], 1)], 2), _vm.productionPackageItems.length === 0 ? _c('div', {
    staticClass: "text-center text-muted py-5"
  }, [_vm._v(" Er staan nog geen producten in dit pakket. "), _c('br'), _vm._v(" Voeg producten toe aan het pakket met het \" "), _c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'plus']
    }
  }), _vm._v(" \" icoon. ")], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": 'productModal' + _vm.productionPackage['@id'],
      "title": "Selecteer een product",
      "cancel-title": "Annuleer",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": function ($event) {
        return _vm.addProduct();
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Product"
    }
  }, [_c('b-form-select', {
    model: {
      value: _vm.selectedProduct,
      callback: function ($$v) {
        _vm.selectedProduct = $$v;
      },
      expression: "selectedProduct"
    }
  }, _vm._l(_vm.productionProducts, function (product, index) {
    return _c('b-form-select-option', {
      key: index,
      attrs: {
        "value": product
      }
    }, [_vm._v(" " + _vm._s(product.name) + " ")]);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }