var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Materialen"
    },
    scopedSlots: _vm._u([{
      key: "filters",
      fn: function () {
        return [_c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-input', {
          attrs: {
            "placeholder": "Naam"
          },
          model: {
            value: _vm.selectedName,
            callback: function ($$v) {
              _vm.selectedName = $$v;
            },
            expression: "selectedName"
          }
        }), _c('b-input', {
          attrs: {
            "placeholder": "Groep"
          },
          model: {
            value: _vm.selectedGroupName,
            callback: function ($$v) {
              _vm.selectedGroupName = $$v;
            },
            expression: "selectedGroupName"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('ResourceList', {
    ref: "grid",
    attrs: {
      "uri": "/api/materials",
      "display-property": "name",
      "params": _vm.params
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "flex-grow-1"
        }, [_vm._v(" " + _vm._s(item.groupName) + " " + _vm._s(item.extra) + " "), _c('br'), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(item.name) + " "), _c('br'), _c('code', [_vm._v(_vm._s(item.reference))])])]), _c('div', {
          staticClass: "d-flex flex-column align-items-end"
        }, [_c('status-badge', {
          staticClass: "ml-1 mb-1",
          attrs: {
            "variant": "light",
            "value": item.color
          }
        }), _c('status-badge', {
          staticClass: "ml-1 mb-1",
          attrs: {
            "variant": "info",
            "value": item.depth,
            "unit": "mm"
          }
        }), _c('status-badge', {
          staticClass: "ml-1",
          attrs: {
            "value": item.weight,
            "unit": "g/m²"
          }
        })], 1)])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }