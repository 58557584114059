var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-5"
  }, [_c('b-card-title', [_vm._v(" Rapport voor "), _vm.campaign !== undefined ? _c('strong', [_vm._v(" " + _vm._s(_vm.campaign.name) + " ")]) : _vm._e()]), _c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "hover": "",
      "striped": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "dark"
    }
  }, [_c('b-tr', [_c('b-th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Totaaloverzicht")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', [_vm._v("Aantal pakketten")]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(_vm._s(_vm.totalPackages))])])], 1), _c('b-tr', [_c('b-td', [_vm._v("Aantal individuele items")]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(_vm._s(_vm.totalItems))])])], 1), _c('b-tr', [_c('b-td', [_vm._v("Totaal basis")]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.totalBasePriceAmount)) + " ")])])], 1), _c('b-tr', [_c('b-td', [_vm._v("Totaal items")]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.totalUnitPriceAmount)) + " ")])])], 1), _c('b-tr', [_c('b-td', [_c('strong', [_vm._v("Totaal")])]), _c('b-td', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.totalUnitPriceAmount + _vm.totalBasePriceAmount)) + " ")])])], 1)], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-5",
    attrs: {
      "no-body": "",
      "header": "Samenstelling per pakket"
    }
  }, _vm._l(_vm.campaignItemsByNonEmptyCampaignPackage, function (campaignPackageCollection) {
    return _c('b-card-body', {
      key: campaignPackageCollection.campaignPackageIri,
      staticClass: "border-bottom"
    }, [_vm.getCampaignPackage(campaignPackageCollection.campaignPackageIri) !== undefined ? _c('b-card-title', [_vm._v(" " + _vm._s(_vm.getCampaignPackage(campaignPackageCollection.campaignPackageIri).name) + " ")]) : _vm._e(), _c('h5', [_vm._v("Inhoud")]), _c('b-table-simple', {
      staticClass: "mb-2",
      attrs: {
        "striped": "",
        "hover": "",
        "responsive": ""
      }
    }, [_c('b-thead', {
      attrs: {
        "head-variant": "dark"
      }
    }, [_c('b-tr', [_c('b-th', [_vm._v("Item")]), _c('b-th', {
      staticClass: "text-right"
    }, [_vm._v("Aantal")]), _c('b-th', {
      staticClass: "text-right"
    }, [_vm._v("Basisprijs")]), _c('b-th', {
      staticClass: "text-right"
    }, [_vm._v("Stuksprijs")])], 1)], 1), _c('b-tbody', _vm._l(campaignPackageCollection.campaignItems, function (campaignItem) {
      return _c('b-tr', {
        key: campaignItem['@id']
      }, [_vm.getRelationProductionProduct(campaignItem.relationProductionProduct) !== undefined ? _c('b-td', [_vm._v(" " + _vm._s(_vm.getRelationProductionProduct(campaignItem.relationProductionProduct).name) + " "), _c('b-badge', {
        attrs: {
          "variant": "light"
        }
      }, [_vm._v(" " + _vm._s(_vm.getRelationProductionProduct(campaignItem.relationProductionProduct).width) + "mm x " + _vm._s(_vm.getRelationProductionProduct(campaignItem.relationProductionProduct).height) + "mm ")]), !_vm.isFirstProductOccurrenceInCampaign(campaignItem) ? _c('b-badge', {
        attrs: {
          "variant": "warning"
        }
      }, [_vm._v(" Basiskosten reeds voldaan door ander product ")]) : _vm._e()], 1) : _vm._e(), _c('b-td', {
        staticClass: "text-right"
      }, [_vm._v(" " + _vm._s(_vm.campaignItemAmount(campaignItem)) + " ")]), _c('b-td', {
        staticClass: "text-right"
      }, [_vm.isFirstProductOccurrenceInCampaign(campaignItem) && _vm.getRelationProductionProduct(campaignItem.relationProductionProduct) !== undefined ? _c('span', [_vm._v(" " + _vm._s(_vm.formatEur(_vm.getRelationProductionProduct(campaignItem.relationProductionProduct).basePrice, 5)) + " ")]) : _c('em', [_vm._v(_vm._s(_vm.formatEur(0, 5)))])]), _vm.getRelationProductionProduct(campaignItem.relationProductionProduct) !== undefined ? _c('b-td', {
        staticClass: "text-right"
      }, [_vm._v(" " + _vm._s(_vm.formatEur(_vm.getRelationProductionProduct(campaignItem.relationProductionProduct).unitPrice, 5)) + " ")]) : _vm._e()], 1);
    }), 1), _c('b-tfoot', [_c('b-tr', {
      staticClass: "font-weight-bold border-top"
    }, [_c('b-td', [_vm._v("Specificatie")]), _c('b-td'), _c('b-td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatEur(_vm.getTotalBasePriceForPackage(campaignPackageCollection))) + " ")]), _c('b-td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatEur(_vm.getTotalUnitPriceForPackage(campaignPackageCollection))) + " ")])], 1)], 1)], 1), _c('h5', [_vm._v("Specificatie aanvraag per winkeltype")]), _c('b-table-simple', {
      staticClass: "mb-0",
      attrs: {
        "striped": "",
        "hover": "",
        "responsive": ""
      }
    }, [_c('b-thead', {
      attrs: {
        "head-variant": "dark"
      }
    }, [_c('b-tr', [_c('b-th', [_vm._v("Winkeltype")]), _c('b-th', {
      staticClass: "text-right"
    }, [_vm._v("Aantal per winkel")]), _c('b-th', {
      staticClass: "text-right"
    }, [_vm._v("Aantal totaal")])], 1)], 1), _c('b-tbody', _vm._l(_vm.storeTypes, function (storeType) {
      return _c('b-tr', {
        key: storeType.id
      }, [_c('b-td', [_vm._v(_vm._s(storeType.name))]), _c('b-td', {
        staticClass: "text-right"
      }, [_vm._v(" " + _vm._s(_vm.amountPerStore(storeType['@id'], campaignPackageCollection.campaignPackageIri)) + " ")]), _c('b-td', {
        staticClass: "text-right"
      }, [_vm._v(" " + _vm._s(_vm.totalAmountOfProducts(storeType['@id'], campaignPackageCollection.campaignPackageIri)) + " ")])], 1);
    }), 1)], 1)], 1);
  }), 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        _vm.isStoresVisible = !_vm.isStoresVisible;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('span', [_vm._v("Vestigingen")]), _c('font-awesome-icon', {
    staticClass: "align-self-center",
    attrs: {
      "icon": ['fas', _vm.isStoresVisible ? 'caret-down' : 'caret-left']
    }
  })], 1)]), _c('b-card-body', {
    staticClass: "p-0"
  }, [_c('b-collapse', {
    staticClass: "m-3 px-1",
    attrs: {
      "id": "stores-collapse"
    },
    model: {
      value: _vm.isStoresVisible,
      callback: function ($$v) {
        _vm.isStoresVisible = $$v;
      },
      expression: "isStoresVisible"
    }
  }, _vm._l(_vm.selectedStores, function (store) {
    return _c('div', {
      key: store['@id'],
      staticClass: "d-flex align-items-center"
    }, [_vm._v(" " + _vm._s(store.name + ' ' + store.city) + " "), _vm._l(_vm.matchingTags(store), function (tag) {
      return _c('b-badge', {
        key: tag['@id'],
        staticClass: "ml-1",
        attrs: {
          "variant": tag.matching ? 'secondary' : 'light'
        }
      }, [_vm._v(" " + _vm._s(tag.title) + " ")]);
    })], 2);
  }), 0)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }