var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('b-navbar-nav', [_c('b-nav-item-dropdown', {
    attrs: {
      "right": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'user'],
            "fixed-with": "",
            "size": "lg"
          }
        })];
      },
      proxy: true
    }], null, false, 2607684197)
  }, [_c('b-dropdown-item-button', {
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Uitloggen ")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }