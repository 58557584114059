var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-sidebar', {
    attrs: {
      "id": "filters",
      "backdrop": "",
      "bg-variant": "secondary",
      "title": "Filter",
      "text-variant": "light",
      "right": ""
    }
  }, [_c('div', {
    staticClass: "px-4 py-2"
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "trim": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tag",
      "label-for": "input-1"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "input-1",
      "trim": "",
      "options": {
        item: 'item1'
      }
    },
    model: {
      value: _vm.tag,
      callback: function ($$v) {
        _vm.tag = $$v;
      },
      expression: "tag"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Media",
      "label-for": "input-1"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "input-1",
      "trim": "",
      "options": {
        print: 'Print',
        signing: 'Signing'
      }
    },
    model: {
      value: _vm.media,
      callback: function ($$v) {
        _vm.media = $$v;
      },
      expression: "media"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Type",
      "label-for": "input-1"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "input-1",
      "trim": "",
      "options": {
        a0: 'A0',
        a1: 'A1',
        a2: 'A2',
        a3: 'A3',
        a4: 'A4',
        a5: 'A5'
      }
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1)], 1)], 1)]), _c('Heading', {
    attrs: {
      "title": "Templates"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filters",
            modifiers: {
              "filters": true
            }
          }],
          staticClass: "ml-1",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Filters ")]), _c('b-btn', {
          staticClass: "ml-1"
        }, [_vm._v("Create")])];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-5"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start"
  }, [_c('h2', [_vm._v("Drukwerk")])]), _c('b-row', {
    staticClass: "mb-3"
  }, _vm._l(12, function (i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('TemplateItem', {
      staticClass: "mb-3"
    })], 1);
  }), 1), _c('h2', [_vm._v("Signing")]), _c('b-row', {
    staticClass: "mb-3"
  }, _vm._l(12, function (i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('TemplateItem', {
      staticClass: "mb-3"
    })], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }