var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loaded ? _c('b-card', {
    attrs: {
      "body-class": "text-center"
    }
  }, [_c('breeding-rhombus-spinner', {
    staticClass: "mx-auto my-5",
    attrs: {
      "animation-duration": 2000,
      "size": 65,
      "color": "#561894"
    }
  }), _c('h4', [_vm._v("Indeling wordt geladen...")]), _c('b-progress', {
    attrs: {
      "variant": "primary",
      "height": "1rem",
      "value": _vm.progress,
      "animated": true
    }
  })], 1) : _vm.item ? _vm._l(_vm.departments, function (department) {
    return _c('StoreDepartmentConfigure', {
      key: department.id,
      attrs: {
        "type": _vm.type,
        "department": department,
        "item": _vm.item
      },
      on: {
        "showModal": function ($event) {
          return _vm.showModal($event);
        }
      }
    });
  }) : _vm._e(), _c('CreateDepartmentModal', {
    on: {
      "create": function ($event) {
        return _vm.createDepartment($event);
      }
    }
  }), _c('CreateSegmentModal', {
    ref: "createSegmentModal",
    on: {
      "create": function ($event) {
        return _vm.createSegment($event);
      }
    }
  }), _c('DuplicateSegmentModal', {
    ref: "duplicateSegmentModal",
    on: {
      "duplicate": function ($event) {
        return _vm.duplicateSegment($event);
      }
    }
  }), _c('CreatePositionModal', {
    ref: "createPositionModal",
    on: {
      "create": function ($event) {
        return _vm.createPosition($event);
      }
    }
  }), _c('CreateSizeModal', {
    ref: "createSizeModal",
    on: {
      "create": function ($event) {
        return _vm.createSize($event);
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }