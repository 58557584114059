var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [!_vm.editName && !_vm.editAll ? _c('div', {
          staticClass: "flex-grow-1",
          on: {
            "click": function ($event) {
              _vm.editName = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.name) + " ")]) : _vm._e(), _vm.editName || _vm.editAll ? _c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          on: {
            "keyup": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.saveSizeName();
            }
          },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), !_vm.editAll ? _c('b-btn', {
          on: {
            "click": function ($event) {
              return _vm.saveSizeName();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'save']
          }
        })], 1) : _vm._e()], 1) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "text-right"
        }, [!_vm.editAll ? _c('b-btn', {
          staticClass: "mr-1",
          attrs: {
            "variant": "info",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              _vm.editAll = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'pencil']
          }
        })], 1) : _vm._e(), _vm.editAll ? _c('b-btn', {
          staticClass: "mr-1",
          attrs: {
            "variant": "info",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              return _vm.saveAll();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'save']
          }
        })], 1) : _vm._e(), _c('b-btn', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'trash']
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [!_vm.editWidth && !_vm.editAll ? _c('div', {
    on: {
      "click": function ($event) {
        _vm.editWidth = true;
      }
    }
  }, [_vm._v(" Width: " + _vm._s(_vm.width) + " ")]) : _vm._e(), _vm.editWidth || _vm.editAll ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-3"
  }, [_vm._v("Width:")]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.saveSizeWidth();
      }
    },
    model: {
      value: _vm.width,
      callback: function ($$v) {
        _vm.width = $$v;
      },
      expression: "width"
    }
  }), !_vm.editAll ? _c('b-btn', {
    on: {
      "click": function ($event) {
        return _vm.saveSizeWidth();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'save']
    }
  })], 1) : _vm._e()], 1) : _vm._e(), !_vm.editHeight && !_vm.editAll ? _c('div', {
    on: {
      "click": function ($event) {
        _vm.editHeight = true;
      }
    }
  }, [_vm._v(" Height: " + _vm._s(_vm.height) + " ")]) : _vm._e(), _vm.editHeight || _vm.editAll ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-3"
  }, [_vm._v("Height:")]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.saveSizeHeight();
      }
    },
    model: {
      value: _vm.height,
      callback: function ($$v) {
        _vm.height = $$v;
      },
      expression: "height"
    }
  }), !_vm.editAll ? _c('b-btn', {
    on: {
      "click": function ($event) {
        return _vm.saveSizeHeight();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'save']
    }
  })], 1) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }