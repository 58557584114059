var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": _vm.title
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "position-sticky",
    staticStyle: {
      "top": "188px"
    }
  }, [_c('b-list-group', _vm._l(6, function (i) {
    return _c('b-list-group-item', {
      key: i,
      attrs: {
        "to": '/producten/categorie/groep-' + i
      }
    }, [_vm._v(" Groep " + _vm._s(i) + " ")]);
  }), 1)], 1)]), _c('b-col', [_c('div', {
    staticClass: "wizards"
  }, [_c('ResourceGrid', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4",
      "xl": "4",
      "uri": "/api/wizards",
      "display-property": "name"
    },
    scopedSlots: _vm._u([{
      key: "no-items",
      fn: function () {
        return [_c('b-card', {
          staticClass: "mb-3"
        }, [_vm._v(" Geen producten gevonden ")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-card', {
          staticClass: "mb-3",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-img', {
          attrs: {
            "top": "",
            "src": "https://www.kpmz.nl/wp-content/uploads/2020/12/wico-1.jpg"
          }
        }), _c('b-card-body', [_c('b-card-title', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('b-card-text', [_c('div', [_vm._v(" Pagina's: " + _vm._s(item.minPages) + " - " + _vm._s(item.maxPages) + " ")]), _c('div', [_vm._v(" Dubbelzijdig: " + _vm._s(item.doubleSided ? 'Ja' : 'Nee') + " ")])]), _c('hr'), _c('b-card-text', {
          staticClass: "text-center"
        }, [_c('b-btn', {
          staticClass: "stretched-link",
          attrs: {
            "variant": "primary",
            "to": '/producten/' + item.id
          }
        }, [_vm._v(" Bestellen ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }