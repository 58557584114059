var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container my-3 my-md-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 offset-md-3"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "text-center p-4 bg-primary mb-3"
  }, [_c('img', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "alt": _vm.brand,
      "src": require("../../assets/logo.png")
    }
  })]), _c('b-card-body', [_vm.busy ? _c('div', [_c('Loading')], 1) : _vm._e(), !_vm.busy ? _c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v("Inloggen")]), _c('b-form-group', [_c('b-form-input', {
    attrs: {
      "id": "username",
      "autocomplete": "username",
      "placeholder": "Username",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('b-form-group', [_c('b-form-input', {
    attrs: {
      "id": "password",
      "placeholder": "Password",
      "type": "password",
      "autocomplete": "current-password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "d-md-flex align-items-md-center"
  }, [_c('b-button', {
    attrs: {
      "size": "lg",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Login ")]), _c('div', {
    staticClass: "ml-md-auto"
  }, [_c('router-link', {
    attrs: {
      "to": "/forgot-password/request"
    }
  }, [_vm._v(" Wachtwoord vergeten? ")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }