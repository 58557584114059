var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-class": "py-2",
      "header-bg-variant": "light"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [!_vm.editing ? _c('div', [_c('h6', {
    staticClass: "mb-0",
    on: {
      "click": _vm.edit
    }
  }, [_vm._v(" " + _vm._s(_vm.segment.name) + " ")])]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editing,
      expression: "editing"
    }]
  }, [_c('b-input-group', [_c('b-input', {
    ref: "segmentName",
    attrs: {
      "value": _vm.segment.name,
      "size": "sm"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.saveItem();
      }
    }
  }), _c('b-input-group-append', [_c('b-btn', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.saveItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'check'],
      "fixed-width": ""
    }
  })], 1)], 1)], 1)], 1)]), _c('b-checkbox', {
    staticClass: "ml-2",
    attrs: {
      "checked": _vm.hasSegment,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggle();
      }
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "size": "sm",
      "variant": "light"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'cog'],
            "fixed-width": ""
          }
        })];
      },
      proxy: true
    }])
  }, [!_vm.editing ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.editing = true;
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'pencil'],
      "fixed-width": ""
    }
  }), _vm._v(" Wijzig naam ")], 1) : _vm._e(), _c('b-dropdown-item', {
    on: {
      "click": _vm.duplicate
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'copy'],
      "fixed-width": ""
    }
  }), _vm._v(" Dupliceer segment ")], 1), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.removeItem(_vm.segment);
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'trash'],
      "fixed-width": ""
    }
  }), _vm._v(" Verwijder segment ")], 1)], 1)], 1)]), _c('b-card-img', {
    style: {
      opacity: _vm.hasSegment ? 1 : 0.5
    },
    attrs: {
      "src": require("@/assets/section.jpg")
    }
  }), _c('b-list-group', {
    style: {
      opacity: _vm.hasSegment ? 1 : 0.5
    },
    attrs: {
      "flush": ""
    }
  }, [_vm._l(_vm.segment.positions, function (position) {
    return _c('b-list-group-item', {
      key: position
    }, [_c('StoreTypePosition', {
      attrs: {
        "position": _vm.positionItem(position),
        "store-type": _vm.item
      }
    })], 1);
  }), _vm.segment.positions.length === 0 ? _c('b-list-group-item', [_c('div', {
    staticClass: "py-3 small font-italic font-weight-light text-muted text-center"
  }, [_vm._v(" Er zijn nog geen posities ")])]) : _vm._e()], 2), _c('b-card-footer', {
    attrs: {
      "footer-bg-variant": "light",
      "footer-class": "p-1"
    }
  }, [_c('b-btn', {
    style: {
      opacity: _vm.hasSegment ? 1 : 0.5
    },
    attrs: {
      "size": "sm",
      "block": "",
      "variant": "light",
      "disabled": !_vm.hasSegment
    },
    on: {
      "click": function ($event) {
        return _vm.showCreatePositionModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'plus'],
      "fixed-width": ""
    }
  }), _vm._v(" Positie ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }