var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    staticClass: "mb-2",
    attrs: {
      "title": "Winkeltypes"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary",
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.createStoreType();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus'],
            "fixed-width": ""
          }
        }), _vm._v(" Nieuw winkeltype ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('b-list-group', _vm._l(_vm.types, function (storeType) {
    return _c('b-list-group-item', {
      key: storeType.id
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "flex-grow-1"
    }, [_vm._v(" " + _vm._s(storeType.name) + " ")]), _c('b-btn', {
      attrs: {
        "size": "sm",
        "variant": "info",
        "to": {
          name: 'StoreTypeItem',
          params: {
            id: storeType.id
          }
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": ['far', 'pencil'],
        "fixed-width": ""
      }
    })], 1), _c('b-btn', {
      staticClass: "ml-1",
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteStoreType(storeType);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": ['far', 'trash'],
        "fixed-width": ""
      }
    })], 1)], 1)]);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }