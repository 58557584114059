var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.relation ? _vm.relation.name : undefined) + " ")]), _c('br'), _c('h5', [_c('b-link', {
    attrs: {
      "to": {
        name: 'Order',
        params: {
          id: _vm.campaign.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.name) + " ")])], 1), _c('CampaignDates', {
    attrs: {
      "campaign": _vm.campaign
    }
  })], 1), _vm.campaign.zipFileAvailable ? _c('div', {
    staticClass: "ml-2"
  }, [_c('b-btn', {
    attrs: {
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.downloadArtwork.apply(null, arguments);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'download']
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "ml-2"
  }, [_c('OrderStatusDropdown', {
    attrs: {
      "size": "sm",
      "campaign": _vm.campaign
    },
    on: {
      "update": function ($event) {
        return _vm.$emit('update');
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }