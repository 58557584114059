var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "template-edit"
  }, [_c('v-stage', {
    ref: "stage",
    staticClass: "stage",
    attrs: {
      "config": _vm.configKonva
    },
    on: {
      "mousedown": function ($event) {
        return _vm.handleStageMouseDown($event);
      },
      "touchstart": function ($event) {
        return _vm.handleStageMouseDown($event);
      }
    }
  }, _vm._l(_vm.layers, function (layer, layerIndex) {
    return _c('v-layer', {
      key: layerIndex
    }, [_vm._l(layer.children, function (node, nodeIndex) {
      return _c(node.nodeType, {
        key: nodeIndex,
        tag: "component",
        attrs: {
          "config": node.config
        },
        on: {
          "transformend": function ($event) {
            return _vm.handleTransformEnd($event);
          },
          "change": function ($event) {
            _vm.setNodeConfig({
              layerIndex: layerIndex,
              nodeIndex: Number(nodeIndex),
              property: $event.prop,
              value: $event.value
            });
          },
          "dragend": function ($event) {
            return _vm.handleTransformEnd($event);
          }
        }
      });
    }), layerIndex === 0 ? _c('v-transformer', {
      ref: "transformer",
      refInFor: true
    }) : _vm._e()], 2);
  }), 1), _c('div', {
    staticClass: "toolbox"
  }, [_c('div', {
    staticClass: "structure"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_vm._v("Structure")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, _vm._l(_vm.layers, function (layer, layerIndex) {
    return _c('b-list-group-item', {
      key: layerIndex
    }, [_vm._v(" Layer " + _vm._s(layerIndex) + " "), _c('b-list-group', _vm._l(layer.children, function (node, nodeIndex) {
      return _c('b-list-group-item', {
        key: nodeIndex,
        staticClass: "p-1 px-2",
        class: {
          active: _vm.selectedNode && _vm.selectedNode[0] === layerIndex && _vm.selectedNode[1] === nodeIndex
        },
        on: {
          "click": function ($event) {
            _vm.selectNode(layerIndex, Number(nodeIndex));
          }
        }
      }, [node.nodeType === 'v-text' ? _c('span', [_c('font-awesome-icon', {
        attrs: {
          "fixed": "",
          "icon": ['fal', 'text']
        }
      }), _c('small', {
        staticClass: "text-muted"
      }, [_vm._v(" " + _vm._s(node.config.text) + " ")])], 1) : node.nodeType === 'v-rect' ? _c('span', [_c('font-awesome-icon', {
        attrs: {
          "fixed": "",
          "icon": ['fal', 'square']
        }
      }), _c('small', {
        staticClass: "text-muted"
      }, [_vm._v(" Rectangle ")])], 1) : _vm._e()]);
    }), 1)], 1);
  }), 1), _c('b-card-header', [_vm._v("Document")]), _c('b-card-body', [_c('b-select', {
    attrs: {
      "size": "sm",
      "options": _vm.sizes
    },
    on: {
      "change": function ($event) {
        return _vm.setSize({
          documentSize: $event
        });
      }
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "properties"
  }, [_c('b-card', {
    attrs: {
      "header": "Properties"
    }
  }, [_vm.selectedNode && typeof _vm.selectedNode[1] !== 'undefined' && _vm.layers[_vm.selectedNode[0]].children[_vm.selectedNode[1]].nodeType === 'v-text' ? _c('TextOptions', {
    attrs: {
      "config": _vm.layers[_vm.selectedNode[0]].children[_vm.selectedNode[1]].config
    },
    on: {
      "config": function ($event) {
        _vm.selectedNode ? _vm.setNodeConfig({
          layerIndex: _vm.selectedNode[0],
          nodeIndex: _vm.selectedNode[1],
          property: $event.prop,
          value: $event.value
        }) : undefined;
      }
    }
  }) : _vm._e(), _vm.selectedNode && typeof _vm.selectedNode[1] !== 'undefined' && _vm.layers[_vm.selectedNode[0]].children[_vm.selectedNode[1]].nodeType === 'v-rect' ? _c('RectOptions', {
    attrs: {
      "config": _vm.layers[_vm.selectedNode[0]].children[_vm.selectedNode[1]].config
    },
    on: {
      "config": function ($event) {
        _vm.selectedNode ? _vm.setNodeConfig({
          layerIndex: _vm.selectedNode[0],
          nodeIndex: _vm.selectedNode[1],
          property: $event.prop,
          value: $event.value
        }) : undefined;
      }
    }
  }) : _vm._e(), _c('Swatches')], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }