var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "franchise-item",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('div', {
    staticClass: "d-flex mb-3"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('b-card-title', {
    staticClass: "mb-0",
    attrs: {
      "title-tag": "h3"
    }
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'CampaignItem',
        params: {
          id: String(_vm.campaign.id)
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.name) + " ")])], 1), _c('b-card-text', {
    staticClass: "text-muted",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.campaign.description) + " ")])], 1), _c('div', {
    staticClass: "ml-2 text-right"
  }, [_c('b-badge', {
    staticClass: "px-2 py-2 rounded",
    staticStyle: {
      "border-radius": "2px",
      "font-size": "12px"
    },
    attrs: {
      "variant": _vm.statusObject !== undefined ? _vm.statusObject.type : null
    }
  }, [_vm._v(" " + _vm._s(_vm.statusObject !== undefined ? _vm.statusObject.label : null) + " ")])], 1)]), _vm.campaign.items.length > 0 && (_vm.campaign.status === _vm.CampaignStatus.ArtworkCorrectionNeeded || _vm.campaign.status === _vm.CampaignStatus.Concept) ? _c('ArtworkProgress', {
    staticClass: "flex-grow-1 my-2",
    attrs: {
      "assigned-artwork-count": _vm.campaign.assignedArtworkCount,
      "unassigned-artwork-count": _vm.campaign.unassignedArtworkCount
    }
  }) : _vm._e(), _vm.campaignItems.length > 0 ? _c('div', {
    staticClass: "d-flex flex-wrap border-top pt-3"
  }, [_vm._l(_vm.firstCampaignItemsWithMedia, function (campaignItem, index) {
    return _c('CampaignItemPreview', {
      key: campaignItem['@id'],
      attrs: {
        "campaign-item": campaignItem
      },
      on: {
        "preview": function ($event) {
          return _vm.$emit('showGallery', _vm.gallery, index);
        }
      }
    });
  }), _vm.extraMedia ? _c('MaterialBlueprint', {
    attrs: {
      "width": 210,
      "height": 297,
      "name": '+' + _vm.extraMedia
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('showGallery', _vm.gallery, _vm.firstCampaignItemsWithMedia.length);
      }
    }
  }) : _vm._e()], 2) : _vm._e()], 1), _c('b-card-footer', {
    staticClass: "d-flex align-items-center border-top-0"
  }, [_c('CampaignDates', {
    attrs: {
      "campaign": _vm.campaign
    }
  }), _c('b-btn', {
    attrs: {
      "to": {
        name: 'CampaignItem',
        params: {
          id: String(_vm.campaign.id)
        }
      },
      "size": "sm",
      "variant": "light"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'pencil'],
      "fixed-width": ""
    }
  })], 1), _c('b-btn', {
    staticClass: "ml-1",
    attrs: {
      "variant": "light",
      "size": "sm",
      "disabled": !_vm.canDelete
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('delete', _vm.campaign);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'trash'],
      "fixed-width": ""
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }