var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "media-object-upload-modal",
      "title": "Bestand uploaden",
      "ok-title": "Uploaden",
      "cancel-title": "Annuleer",
      "ok-disabled": !_vm.selectedFile,
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": function ($event) {
        return _vm.$emit('upload', _vm.selectedFile);
      },
      "hidden": function ($event) {
        _vm.selectedFile = undefined;
      }
    }
  }, [_c('b-file', {
    model: {
      value: _vm.selectedFile,
      callback: function ($$v) {
        _vm.selectedFile = $$v;
      },
      expression: "selectedFile"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }