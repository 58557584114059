var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Globaal DNA"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "size": "lg"
          },
          on: {
            "click": _vm.getDna
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus'],
            "fixed-width": ""
          }
        }), _vm._v(" Nieuwe Afdeling ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.$store.state.storeDna.globalDna, function (department) {
    return _c('div', {
      key: department.id,
      staticClass: "mt-3 col-lg-6"
    }, [_c('DnaItem', {
      attrs: {
        "department": department
      }
    })], 1);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }