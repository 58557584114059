var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('MaterialBlueprint', {
    staticClass: "mr-0 shadow",
    class: {
      'border-primary': _vm.selected
    },
    attrs: {
      "clickable": "",
      "wide": "",
      "title": _vm.mediaObject.name,
      "front-img": _vm.imageUrl,
      "width": _vm.dimensions ? _vm.dimensions.width : 1,
      "height": _vm.dimensions ? _vm.dimensions.height : 1,
      "show-dimensions": _vm.dimensions !== undefined,
      "dimension-unit": _vm.dimensions ? _vm.dimensions.unit : undefined,
      "material": _vm.size
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_vm.selectable ? _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top.noninteractive",
            modifiers: {
              "hover": true,
              "top": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "title": "Selecteer",
            "variant": "light",
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('select');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'check']
          }
        })], 1) : _vm._e(), _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top.noninteractive",
            modifiers: {
              "hover": true,
              "top": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "title": "Voorbeeld bekijken",
            "variant": "light",
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('preview');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'expand']
          }
        })], 1), _vm.editable ? [_c('hr'), _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.bottom.noninteractive",
            modifiers: {
              "hover": true,
              "bottom": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "variant": "light",
            "title": "Bestand aanpassen"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('edit');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'pencil']
          }
        })], 1), _c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.bottom.noninteractive",
            modifiers: {
              "hover": true,
              "bottom": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "variant": "light",
            "title": "Verwijder bestand"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('delete');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'trash']
          }
        })], 1)] : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }