var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": _vm.campaign ? _vm.campaign.name : undefined
    },
    scopedSlots: _vm._u([{
      key: "breadcrumbs",
      fn: function () {
        return [_c('b-breadcrumb-item', {
          attrs: {
            "to": {
              name: 'Campaigns'
            }
          }
        }, [_vm._v(" Campagnes ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.campaign ? _c('OrderStatusDropdown', {
          attrs: {
            "size": "lg",
            "campaign": _vm.campaign
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "py-5"
  }, [!_vm.loaded ? _c('LoadingCard', {
    attrs: {
      "title": "Campagne wordt geladen..."
    }
  }) : _vm.campaign ? _c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-secondary",
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Pakketten"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Pakketten "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light"
          }
        }, [_vm._v(" " + _vm._s(_vm.campaignPackages.length) + " ")])];
      },
      proxy: true
    }])
  }, [_vm.campaign.items.length > 0 ? _c('div', {
    staticClass: "mt-3 d-flex align-items-center rounded bg-white px-3 py-2 shadow border"
  }, [_c('b-check', {
    attrs: {
      "switch": "",
      "variant": "light"
    },
    model: {
      value: _vm.filterArtwork,
      callback: function ($$v) {
        _vm.filterArtwork = $$v;
      },
      expression: "filterArtwork"
    }
  }, [_vm._v(" Toon enkel missende artwork ")]), _c('ArtworkProgress', {
    staticClass: "flex-grow-1 ml-5",
    attrs: {
      "assigned-artwork-count": _vm.campaign.assignedArtworkCount,
      "unassigned-artwork-count": _vm.campaign.unassignedArtworkCount
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mt-3"
  }, [_vm.campaignPackages.length === 0 ? _c('b-card', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "text-center m-3"
  }, [_vm._v(" Er zijn nog geen pakketten toegevoegd. ")]), _vm.campaignIsEditable ? _c('div', {
    staticClass: "text-center m-3"
  }, [_c('b-button', {
    attrs: {
      "size": "lg"
    },
    on: {
      "click": function ($event) {
        return _vm.showPackageModal();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": ['far', 'plus'],
      "fixed-width": ""
    }
  }), _vm._v(" Voeg pakket toe ")], 1)], 1) : _vm._e()]) : _vm._e(), _c('transition-group', {
    attrs: {
      "tag": "div",
      "name": "slide-fade"
    }
  }, _vm._l(_vm.filteredCampaignItemsByCampaignPackage, function (campaignPackageCollection) {
    return _c('div', {
      key: campaignPackageCollection.campaignPackageIri,
      staticClass: "mb-5 border rounded shadow"
    }, [_c('b-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "lg": "9"
      }
    }, [_c('CampaignItemPackage', {
      attrs: {
        "package-iri": campaignPackageCollection.campaignPackageIri,
        "items": campaignPackageCollection.campaignItems,
        "disabled": !_vm.isArtworkMutable
      },
      on: {
        "preview": _vm.showPreview,
        "browse": _vm.showBrowse,
        "upload": _vm.showUpload,
        "reset": _vm.reset,
        "move": _vm.showMove
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3"
      }
    }, [_c('div', {
      staticClass: "position-sticky h-100 shadow-none rounded-right bg-white border-left",
      staticStyle: {
        "top": "188px"
      }
    }, [_c('div', {
      staticClass: "bg-medium text-dark rounded-top-right"
    }, [_c('div', {
      staticClass: "d-flex align-items-center px-3 py-2"
    }, [_c('div', {
      staticClass: "flex-grow-1"
    }), _c('b-button', {
      staticClass: "ml-1",
      attrs: {
        "size": "sm",
        "variant": "secondary"
      }
    }, [_vm._v(" " + _vm._s(_vm.campaignPackageCount(campaignPackageCollection.campaignPackageIri)) + " ")])], 1)]), _c('div', {}, _vm._l(_vm.storeTypes, function (storeType, index) {
      return _c('div', {
        key: storeType['@id']
      }, [_c('CampaignPackageStoreType', {
        attrs: {
          "campaign-package-iri": campaignPackageCollection.campaignPackageIri,
          "disabled": !_vm.campaignIsEditable,
          "store-type": storeType,
          "show-bottom-border": index + 1 === _vm.storeTypes.length
        }
      })], 1);
    }), 0)])])], 1)], 1);
  }), 0), _vm.campaignPackages.length > 0 && _vm.campaignIsEditable ? _c('div', {
    staticClass: "text-right mt-3 mb-5"
  }, [_c('b-button', {
    attrs: {
      "size": "lg"
    },
    on: {
      "click": function ($event) {
        return _vm.showPackageModal();
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": ['far', 'plus'],
      "fixed-width": ""
    }
  }), _vm._v(" Meer pakketten ")], 1)], 1) : _vm._e()], 1)]), _c('b-tab', {
    attrs: {
      "title": "Instellingen"
    }
  }, [_c('b-card', {
    staticClass: "mt-3 mb-5",
    attrs: {
      "header-class": "p-0"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    attrs: {
      "debounce": "500",
      "disabled": !_vm.campaignIsEditable,
      "placeholder": _vm.campaignName
    },
    model: {
      value: _vm.campaignName,
      callback: function ($$v) {
        _vm.campaignName = $$v;
      },
      expression: "campaignName"
    }
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Campagne periode"
    }
  }, [_c('date-picker', {
    attrs: {
      "range": "",
      "disabled": !_vm.campaignIsEditable
    },
    on: {
      "input": _vm.updateCampaignPeriod
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('div', {
          staticClass: "mx-input",
          class: {
            disabled: !_vm.campaignIsEditable
          }
        }, [_vm._v(" " + _vm._s(_vm.campaignPeriodString) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.campaignPeriod,
      callback: function ($$v) {
        _vm.campaignPeriod = $$v;
      },
      expression: "campaignPeriod"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gewenste leverdatum"
    }
  }, [_c('date-picker', {
    attrs: {
      "value-type": "YYYY-MM-DD",
      "disabled": !_vm.campaignIsEditable
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('div', {
          staticClass: "mx-input",
          class: {
            disabled: !_vm.campaignIsEditable
          }
        }, [_vm._v(" " + _vm._s(_vm.dateFormatter.stringify(_vm.campaignExpectedDeliveryDate)) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.campaignExpectedDeliveryDate,
      callback: function ($$v) {
        _vm.campaignExpectedDeliveryDate = $$v;
      },
      expression: "campaignExpectedDeliveryDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Artwork aanleverdatum"
    }
  }, [_c('date-picker', {
    attrs: {
      "value": _vm.campaign.artworkLatestDate,
      "value-type": "YYYY-MM-DD",
      "formatter": _vm.dateFormatter,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Beschrijving"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "debounce": "500",
      "disabled": !_vm.campaignIsEditable
    },
    model: {
      value: _vm.campaignDescription,
      callback: function ($$v) {
        _vm.campaignDescription = $$v;
      },
      expression: "campaignDescription"
    }
  })], 1), _c('CampaignTags', {
    attrs: {
      "campaign": _vm.campaign,
      "editable": _vm.campaignIsEditable
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Rapport"
    }
  }, [_c('CampaignReport', {
    staticClass: "mt-3",
    attrs: {
      "campaign": _vm.campaign
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Gebeurtenissen"
    }
  }, [_vm.campaign ? _c('b-card', {
    staticClass: "mt-3"
  }, [_c('CampaignStatusTable', {
    attrs: {
      "campaign": _vm.campaign
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('MediaObjectGallery', {
    ref: "gallery"
  }), _c('MediaObjectBrowser', {
    attrs: {
      "selected-campaign-item-iri": _vm.selectedCampaignItemIri
    },
    on: {
      "select": _vm.setCampaignItemMediaObject
    }
  }), _c('MediaObjectUploadModal', {
    on: {
      "upload": _vm.upload
    }
  }), _c('b-modal', {
    attrs: {
      "id": "package-move-modal",
      "title": "Pakket verplaatsen",
      "cancel-title": "Annuleer",
      "ok-title": "Verplaatsen",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": function ($event) {
        return _vm.movePackage($event);
      },
      "close": _vm.clearMovePackage,
      "cancel": _vm.clearMovePackage
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "border rounded p-3 mb-3",
    class: {
      'border-secondary': _vm.packageMoveExisting === true
    },
    on: {
      "click": function ($event) {
        _vm.packageMoveExisting = true;
      }
    }
  }, [_c('div', [_vm._v(" Naar "), _c('strong', [_vm._v("bestaande")]), _vm._v(" campagne ")]), _c('b-select', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.packageMoveExisting === true,
      expression: "packageMoveExisting === true"
    }],
    staticClass: "mt-3",
    model: {
      value: _vm.packageMoveCampaignId,
      callback: function ($$v) {
        _vm.packageMoveCampaignId = $$v;
      },
      expression: "packageMoveCampaignId"
    }
  }, [_vm._v(" jdskfhsdkjfh "), _c('b-select-option', {
    attrs: {
      "value": undefined
    }
  }, [_vm._v(" - Selecteer een bestaande campagne - ")]), _vm._l(_vm.availableCampaigns, function (item) {
    return _c('b-select-option', {
      key: item['@id'],
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1), _c('div', {
    staticClass: "border rounded p-3",
    class: {
      'border-secondary': _vm.packageMoveExisting === false
    },
    on: {
      "click": function ($event) {
        _vm.packageMoveExisting = false;
      }
    }
  }, [_c('div', [_vm._v(" Maak "), _c('strong', [_vm._v("nieuwe")]), _vm._v(" campagne ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.packageMoveExisting === false,
      expression: "packageMoveExisting === false"
    }],
    staticClass: "mt-3"
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Naam van nieuwe campagne"
    },
    model: {
      value: _vm.packageMoveCampaignName,
      callback: function ($$v) {
        _vm.packageMoveCampaignName = $$v;
      },
      expression: "packageMoveCampaignName"
    }
  })], 1)])])], 1), _c('b-modal', {
    attrs: {
      "id": "packageModal",
      "title": "Pakket toevoegen",
      "cancel-title": "Annuleer",
      "ok-title": "Toevoegen",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": function ($event) {
        return _vm.addPackage($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addPackage($event);
      }
    },
    model: {
      value: _vm.newPackageName,
      callback: function ($$v) {
        _vm.newPackageName = $$v;
      },
      expression: "newPackageName"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pakketindeling"
    }
  }, [_c('b-form-select', {
    model: {
      value: _vm.newPackage,
      callback: function ($$v) {
        _vm.newPackage = $$v;
      },
      expression: "newPackage"
    }
  }, _vm._l(_vm.productionPackages, function (item, index) {
    return _c('b-form-select-option', {
      key: index,
      attrs: {
        "value": item['@id']
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }