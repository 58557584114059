var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Afdelingen"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('div', {
    staticClass: "departments"
  }, [_c('b-list-group', [_vm._l(_vm.departments, function (department) {
    return _c('b-list-group-item', {
      key: department,
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "flex-grow-1"
    }, [_vm._v(_vm._s(department))]), _c('b-btn', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteDepartment(department);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": ['far', 'trash-alt']
      }
    })], 1)], 1);
  }), _c('b-list-group-item', [_c('b-input-group', [_c('b-form-input', {
    model: {
      value: _vm.newDepartment,
      callback: function ($$v) {
        _vm.newDepartment = $$v;
      },
      expression: "newDepartment"
    }
  }), _c('b-btn', {
    on: {
      "click": _vm.addDepartment
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fal', 'plus'],
      "fixed-width": ""
    }
  }), _vm._v(" Maak afdeling ")], 1)], 1)], 1)], 2)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }