var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    staticClass: "mb-2",
    attrs: {
      "title": _vm.item !== undefined ? _vm.item.name : undefined
    },
    scopedSlots: _vm._u([{
      key: "breadcrumbs",
      fn: function () {
        return [_c('b-breadcrumb-item', {
          attrs: {
            "to": {
              name: 'StoreTypes'
            }
          }
        }, [_vm._v(" Winkeltypes ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          staticClass: "ml-2",
          attrs: {
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.show('createSize');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus'],
            "fixed-width": ""
          }
        }), _vm._v(" Nieuwe afmeting ")], 1), _c('b-btn', {
          staticClass: "ml-2",
          attrs: {
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.show('createDepartment');
            }
          }
        }, [_c('font-awesome-icon', {
          staticClass: "mr-1",
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'plus']
          }
        }), _vm._v(" Afdeling ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_vm.item !== undefined ? _c('div', [_c('b-card', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('b-form-input', {
    attrs: {
      "size": "sm"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  })], 1), _c('b-btn', {
    staticClass: "ml-1",
    attrs: {
      "size": "sm",
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.saveStoreTypeName();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'save'],
      "fixed-width": ""
    }
  })], 1)], 1)])], 1) : _vm._e(), _c('PositionConfigurator', {
    attrs: {
      "type": "StoreType",
      "item": _vm.item
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }