var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Afmetingen"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          attrs: {
            "variant": "primary",
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.show('createSize');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus']
          }
        }), _vm._v(" Nieuwe afmeting ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-5"
  }, [_c('b-row', _vm._l(_vm.sizes, function (size) {
    return _c('b-col', {
      key: size['@id'],
      staticClass: "mb-3",
      attrs: {
        "md": "4"
      }
    }, [_c('SizeItem', {
      attrs: {
        "id": size['@id']
      }
    })], 1);
  }), 1)], 1), _c('CreateSizeModal', {
    ref: "createSizeModal",
    on: {
      "create": function ($event) {
        return _vm.createSize($event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }