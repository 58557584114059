var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Vestigingen"
    },
    scopedSlots: _vm._u([{
      key: "filters",
      fn: function () {
        return [_c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-input', {
          attrs: {
            "placeholder": "Naam"
          },
          model: {
            value: _vm.selectedName,
            callback: function ($$v) {
              _vm.selectedName = $$v;
            },
            expression: "selectedName"
          }
        })], 1), _c('b-input-group', {
          staticClass: "ml-1",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-input', {
          attrs: {
            "placeholder": "Plaats"
          },
          model: {
            value: _vm.selectedCity,
            callback: function ($$v) {
              _vm.selectedCity = $$v;
            },
            expression: "selectedCity"
          }
        })], 1), _c('b-input-group', {
          staticClass: "ml-1",
          attrs: {
            "size": "sm"
          }
        }, [_c('MultiSelect', {
          attrs: {
            "multiple": true,
            "options": _vm.storeTypes,
            "track-by": "@id",
            "label": "name",
            "placeholder": "Winkeltype",
            "hide-selected": true,
            "close-on-select": false
          },
          model: {
            value: _vm.selectedStoreTypes,
            callback: function ($$v) {
              _vm.selectedStoreTypes = $$v;
            },
            expression: "selectedStoreTypes"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('ResourceGrid', {
    ref: "grid",
    staticClass: "stores mb-3",
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4",
      "display-property": "name",
      "uri": "/api/stores",
      "params": _vm.params
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('StoreItem', {
          staticClass: "mb-3",
          attrs: {
            "item": item
          }
        })];
      }
    }, {
      key: "no-items",
      fn: function () {
        return [_c('b-card', {
          staticClass: "mb-3"
        }, [_c('b-card-title', [_vm._v("Geen vestigingen gevonden")]), _vm.selectedStoreTypes.length > 0 || _vm.selectedName || _vm.selectedCity ? _c('b-card-text', [_vm._v(" Er voldoen geen vestigingen aan de zoekcriteria. ")]) : _c('b-card-text', [_vm._v(" Neem contact op met uw beheerder om vestigingen toe te voegen. ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }