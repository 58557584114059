var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    attrs: {
      "label-size": "sm",
      "label": "x",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.x
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'x',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "y",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.y
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'y',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-size": "sm",
      "label": "scaleX",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.scaleX
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'scaleX',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "scaleY",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.scaleY
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'scaleY',
          value: $event
        });
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "rotation",
      "label-size": "sm",
      "label-cols-sm": "4",
      "label-cols-lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "size": "sm",
      "type": "number",
      "value": _vm.config.rotation
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('config', {
          prop: 'rotation',
          value: $event
        });
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }