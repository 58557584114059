var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('b-navbar-nav', [_c('b-nav-item-dropdown', {
    ref: "dropdown",
    attrs: {
      "right": "",
      "no-caret": "",
      "menu-class": 'p-0 shadow-lg ' + (_vm.count === 0 ? 'd-none' : null)
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('font-awesome-layers', [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'arrow-to-top'],
            "fixed-with": "",
            "size": "lg"
          }
        }), _vm.count > 0 ? _c('font-awesome-layers-text', {
          attrs: {
            "counter": "",
            "value": _vm.count,
            "position": "top-right",
            "transform": "shrink-5 right-20"
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 1070779843)
  }, [_vm.count > 0 ? _c('div', {
    staticClass: "bg-primary text-light border-bottom text-left p-3 px-3"
  }, [_vm._v(" Voortgang ")]) : _vm._e(), _vm.count > 0 ? _c('transition-group', {
    staticClass: "py-2",
    staticStyle: {
      "width": "300px",
      "max-height": "500px",
      "overflow-y": "scroll",
      "overflow-x": "hidden",
      "position": "relative"
    },
    attrs: {
      "name": "slide",
      "tag": "div"
    }
  }, [_vm._l(_vm.uploadMediaObjects, function (mediaObject, index) {
    return _c('div', {
      key: mediaObject.index
    }, [index > 0 ? _c('b-dd-divider') : _vm._e(), _c('UploadProgressItem', {
      attrs: {
        "media-object": mediaObject
      },
      on: {
        "clear": function ($event) {
          return _vm.clearItem(mediaObject['@id']);
        }
      }
    })], 1);
  }), _vm._l(_vm.watchedMediaObjects, function (mediaObject, index) {
    return _c('div', {
      key: mediaObject['@id']
    }, [index > 0 ? _c('b-dd-divider') : _vm._e(), _c('MediaObjectProgressItem', {
      attrs: {
        "media-object": mediaObject
      },
      on: {
        "clear": function ($event) {
          return _vm.clearItem(mediaObject['@id']);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _vm.count === 0 ? _c('div', {
    staticClass: "py-3 px-5 text-center text-muted"
  }, [_vm._v(" Geen uploadnotificaties beschikbaar ")]) : _vm._e(), _vm.count > 0 ? _c('div', {
    staticClass: "bg-light border-top text-center p-2"
  }, [_c('b-btn', {
    staticClass: "sm",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clear.apply(null, arguments);
      }
    }
  }, [_vm._v(" Wis alle notificaties ")])], 1) : _vm._e()], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }