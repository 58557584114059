var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "createDepartment",
      "title": "Maak nieuwe afdeling",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": _vm.create
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submit();
      }
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }