var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Assets"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return undefined;
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('div', {
    staticClass: "assets"
  }, [_c('b-row', _vm._l(10, function (i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "6",
        "md": "4",
        "lg": "3",
        "xl": "2"
      }
    }, [_c('AssetItem', {
      staticClass: "mb-3"
    })], 1);
  }), 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }