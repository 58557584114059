var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.product ? _c('MaterialBlueprint', {
    attrs: {
      "title": _vm.product.name,
      "height": _vm.productHeight,
      "width": _vm.productWidth,
      "show-dimensions": _vm.knownDimensions
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('delete');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'trash'],
            "fixed-width": ""
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 839394467)
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }