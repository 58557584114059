var render = function () {
  var _vm$relationProductio, _vm$mediaObject, _vm$relationProductio2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MaterialBlueprint', {
    attrs: {
      "width": (_vm$relationProductio = _vm.relationProductionProduct) === null || _vm$relationProductio === void 0 ? void 0 : _vm$relationProductio.width,
      "height": _vm.relationProductionProduct !== undefined ? _vm.relationProductionProduct.height : undefined,
      "show-dimensions": "",
      "front-img": (_vm$mediaObject = _vm.mediaObject) === null || _vm$mediaObject === void 0 ? void 0 : _vm$mediaObject.thumbnailUrl,
      "colors": (_vm$relationProductio2 = _vm.relationProductionProduct) === null || _vm$relationProductio2 === void 0 ? void 0 : _vm$relationProductio2.colors
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.noninteractive",
            modifiers: {
              "hover": true,
              "noninteractive": true
            }
          }],
          attrs: {
            "title": "Voorbeeld bekijken",
            "variant": "light",
            "size": "lg"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('preview');
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "fixed-width": "",
            "icon": ['far', 'expand']
          }
        })], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }