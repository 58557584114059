var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container my-3 my-md-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 offset-md-3"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "text-center p-4 bg-primary mb-3"
  }, [_c('img', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "alt": _vm.brand,
      "src": require("./../../assets/logo.png")
    }
  })]), _c('b-card-body', [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v("Wat is je e-mailadres?")]), _c('FormulateForm', {
    attrs: {
      "form-errors": _vm.unmappedViolations,
      "errors": _vm.mappedViolations,
      "schema": _vm.schema
    },
    on: {
      "submit": _vm.onSubmit
    },
    model: {
      value: _vm.formData,
      callback: function ($$v) {
        _vm.formData = $$v;
      },
      expression: "formData"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }