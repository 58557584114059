var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Orders"
    }
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('ResourceList', {
    ref: "list",
    attrs: {
      "uri": "/api/campaigns",
      "display-property": "name",
      "order": {
        createdAt: 'DESC'
      },
      "params": {
        status: [_vm.CampaignStatus.Concept, _vm.CampaignStatus.PriceInReview, _vm.CampaignStatus.ArtworkAttaching, _vm.CampaignStatus.ArtworkInReview, _vm.CampaignStatus.ArtworkCorrectionNeeded, _vm.CampaignStatus.Producing, _vm.CampaignStatus.Shipped, _vm.CampaignStatus.Finalized]
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('CampaignListItem', {
          attrs: {
            "campaign": item
          },
          on: {
            "update": function ($event) {
              return _vm.refresh();
            }
          }
        })];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }