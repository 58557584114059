var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Tags"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.createTag",
            modifiers: {
              "createTag": true
            }
          }],
          staticClass: "ml-2",
          attrs: {
            "variant": "primary",
            "size": "lg"
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus'],
            "fixed-width": ""
          }
        }), _vm._v(" Nieuwe Tag ")], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-3 my-md-5"
  }, [_c('b-row', _vm._l(_vm.tags, function (tag) {
    return _c('b-col', {
      key: tag['@id'],
      attrs: {
        "cols": "4",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('TagItem', {
      attrs: {
        "tag": tag
      },
      on: {
        "linkTag": function ($event) {
          return _vm.showLinkTag($event);
        }
      }
    })], 1);
  }), 1)], 1), _c('b-modal', {
    attrs: {
      "id": "createTag",
      "title": "Maak een Tag",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": _vm.create
    }
  }, [_vm._v(" Titel: "), _c('b-form-input', {
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.create.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newTitle,
      callback: function ($$v) {
        _vm.newTitle = $$v;
      },
      expression: "newTitle"
    }
  })], 1), _c('LinkTagModal', {
    attrs: {
      "tag": _vm.editTag
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }