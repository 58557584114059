var render = function () {
  var _vm$items$_vm$current;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "media-object-gallery",
    class: {
      hidden: _vm.hidden
    }
  }, [_c('div', {
    staticClass: "gallery-actions"
  }, [_c('div', {
    staticClass: "title text-light text-truncate"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm.items[_vm.current] ? _c('span', [_vm._v(" " + _vm._s(_vm.items[_vm.current].title) + " ")]) : _vm._e()]), _c('h5', {
    staticClass: "text-truncate"
  }, [(_vm$items$_vm$current = _vm.items[_vm.current]) !== null && _vm$items$_vm$current !== void 0 && _vm$items$_vm$current.subTitle ? _c('span', [_vm._v(" " + _vm._s(_vm.items[_vm.current].subTitle) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "flex-shrink-0"
  }, [_c('b-btn', {
    staticClass: "ml-2",
    attrs: {
      "disabled": _vm.zoom === _vm.defaultZoom,
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.zoomReset
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "lg",
      "icon": ['far', 'search']
    }
  })], 1), _c('b-btn', {
    staticClass: "ml-2",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.zoomOut
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "lg",
      "icon": ['far', 'search-minus']
    }
  })], 1), _c('b-btn', {
    staticClass: "ml-2",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.zoomIn
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "lg",
      "icon": ['far', 'search-plus']
    }
  })], 1), _c('b-btn', {
    staticClass: "ml-5",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "lg",
      "icon": ['far', 'times']
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "main"
  }, [_vm.items.length > 1 ? _c('div', {
    staticClass: "previous"
  }, [_c('b-btn', {
    attrs: {
      "variant": "dark",
      "disabled": !_vm.hasPrevious
    },
    on: {
      "click": _vm.previous
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "lg",
      "icon": ['far', 'chevron-left']
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    ref: "images",
    staticClass: "images"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "thumbnail"
    }, [_c('b-img', {
      class: {
        'd-none': _vm.loaded[index] === false
      },
      style: {
        height: _vm.zoom + '%'
      },
      attrs: {
        "src": item.imageSrc,
        "draggable": "false"
      }
    }), _c('breeding-rhombus-spinner', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.loaded[index] === false,
        expression: "loaded[index] === false"
      }],
      staticClass: "mx-auto my-auto",
      attrs: {
        "animation-duration": 2000,
        "size": 120,
        "color": "#fff"
      }
    })], 1);
  }), 0), _vm.items.length > 1 ? _c('div', {
    staticClass: "next"
  }, [_c('b-btn', {
    attrs: {
      "variant": "dark",
      "disabled": !_vm.hasNext
    },
    on: {
      "click": _vm.next
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "size": "lg",
      "icon": ['far', 'chevron-right']
    }
  })], 1)], 1) : _vm._e()]), _vm.items.length > 1 ? _c('div', {
    staticClass: "preview"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('b-img', {
      key: index,
      staticClass: "thumbnail",
      class: {
        'border-primary': index === _vm.current
      },
      style: {
        opacity: index === _vm.current ? 1 : 0.7
      },
      attrs: {
        "draggable": "false",
        "src": item.thumbnailSrc,
        "thumbnail": ""
      },
      on: {
        "click": function ($event) {
          return _vm.setCurrent(index);
        }
      }
    });
  }), 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }