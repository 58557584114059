var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Heading', {
    attrs: {
      "title": "Campagnes"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('b-btn', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.createCampaign",
            modifiers: {
              "createCampaign": true
            }
          }],
          staticClass: "ml-2",
          attrs: {
            "variant": "primary",
            "size": "lg"
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['fal', 'plus'],
            "fixed-width": ""
          }
        }), _vm._v(" Nieuwe campagne ")], 1)];
      },
      proxy: true
    }, {
      key: "filters",
      fn: function () {
        return [_c('b-btn-toolbar', {
          staticClass: "w-100"
        }, [_c('b-input', {
          staticClass: "w-25 mb-0 mr-auto",
          attrs: {
            "placeholder": "Naam",
            "size": "sm",
            "type": "search"
          },
          model: {
            value: _vm.selectedName,
            callback: function ($$v) {
              _vm.selectedName = $$v;
            },
            expression: "selectedName"
          }
        }), _c('b-btn-group', {
          staticClass: "ml-2",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-btn', {
          attrs: {
            "variant": _vm.preferences.filters.state === 'notComplete' ? 'medium' : 'outline-medium text-dark'
          },
          on: {
            "click": function ($event) {
              _vm.preferences.filters.state = 'notComplete';
            }
          }
        }, [_vm._v(" Niet afgerond ")]), _c('b-btn', {
          attrs: {
            "variant": _vm.preferences.filters.state === 'complete' ? 'medium' : 'outline-medium text-dark'
          },
          on: {
            "click": function ($event) {
              _vm.preferences.filters.state = 'complete';
            }
          }
        }, [_vm._v(" Afgerond ")])], 1), _c('b-btn-group', {
          staticClass: "ml-2",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-btn', {
          staticClass: "text-dark",
          attrs: {
            "variant": _vm.preferences.display === 'detail' ? 'medium' : 'outline-medium'
          },
          on: {
            "click": function ($event) {
              _vm.preferences.display = 'detail';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'th-large']
          }
        })], 1), _c('b-btn', {
          staticClass: "text-dark",
          attrs: {
            "variant": _vm.preferences.display === 'list' ? 'medium' : 'outline-medium'
          },
          on: {
            "click": function ($event) {
              _vm.preferences.display = 'list';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": ['far', 'list']
          }
        })], 1)], 1), _c('b-btn-group', {
          staticClass: "ml-2",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-dropdown', {
          attrs: {
            "variant": "light",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm._f("t")(_vm.preferences.sortField)) + " ")];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.preferences.sortField = 'startAt';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("t")('startAt')) + " ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.preferences.sortField = 'expectedDeliveryDate';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("t")('expectedDeliveryDate')) + " ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.preferences.sortField = 'createdAt';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("t")('createdAt')) + " ")])], 1), _c('b-dropdown', {
          attrs: {
            "variant": "light",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm._f("t")(_vm.preferences.sortDirection)) + " ")];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.preferences.sortDirection = 'ASC';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("t")('ASC')) + " ")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.preferences.sortDirection = 'DESC';
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("t")('DESC')) + " ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('b-container', {
    staticClass: "my-5"
  }, [!_vm.loaded ? _c('LoadingCard', {
    attrs: {
      "title": "Campagnes worden geladen..."
    }
  }) : [_vm.campaigns.length === 0 ? _c('b-card', {
    staticClass: "mb-3"
  }, [_vm._v(" Geen campagnes gevonden ")]) : _vm._e(), _vm.preferences.display === 'detail' ? _c('transition-group', {
    staticClass: "campaigns",
    attrs: {
      "tag": "div",
      "name": "slide-fade"
    }
  }, _vm._l(_vm.campaigns, function (campaign) {
    return _c('CampaignItem', {
      key: campaign.id,
      staticClass: "mb-3",
      attrs: {
        "campaign": campaign
      },
      on: {
        "delete": _vm.deleteCampaign,
        "showGallery": _vm.showGallery
      }
    });
  }), 1) : _c('transition-group', {
    staticClass: "campaigns list-group",
    attrs: {
      "tag": "div",
      "name": "slide-fade"
    }
  }, _vm._l(_vm.campaigns, function (campaign) {
    return _c('CampaignListItem', {
      key: campaign.id,
      attrs: {
        "campaign": campaign
      },
      on: {
        "delete": _vm.deleteCampaign
      }
    });
  }), 1)]], 2), _c('CreateCampaignModal', {
    on: {
      "create": _vm.createCampaign
    }
  }), _c('MediaObjectGallery', {
    ref: "gallery"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }