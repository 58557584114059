var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.campaignPackage ? _c('div', {
    staticClass: "border-0 shadow-none rounded-left h-100 bg-white"
  }, [_c('div', {
    staticClass: "d-flex align-items-center bg-medium text-dark py-2 px-3 rounded-top-left"
  }, [!_vm.editName ? _c('div', {
    staticClass: "flex-grow-1"
  }, [_vm._v(" " + _vm._s(_vm.campaignPackage.name) + " "), !_vm.editName ? _c('b-button', {
    staticClass: "text-dark",
    attrs: {
      "variant": "link",
      "size": "sm",
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.editName = true;
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'pencil'],
      "fixed-width": ""
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.editName ? _c('b-input', {
    ref: "nameInput",
    staticClass: "flex-grow-1 py-0",
    staticStyle: {
      "height": "auto"
    },
    attrs: {
      "placeholder": _vm.campaignPackageName,
      "debounce": "500",
      "autofocus": ""
    },
    on: {
      "blur": function ($event) {
        _vm.editName = false;
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.editName = false;
      }
    },
    model: {
      value: _vm.campaignPackageName,
      callback: function ($$v) {
        _vm.campaignPackageName = $$v;
      },
      expression: "campaignPackageName"
    }
  }) : _vm._e(), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom.noninteractive",
      modifiers: {
        "hover": true,
        "bottom": true,
        "noninteractive": true
      }
    }],
    staticClass: "ml-1",
    attrs: {
      "title": "Verplaats pakket",
      "disabled": _vm.disabled,
      "variant": "medium",
      "size": "sm"
    },
    on: {
      "click": _vm.showMovePackageModal
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'share'],
      "fixed-width": ""
    }
  })], 1), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom.noninteractive",
      modifiers: {
        "hover": true,
        "bottom": true,
        "noninteractive": true
      }
    }],
    staticClass: "ml-1",
    attrs: {
      "title": "Verwijder pakket",
      "disabled": _vm.disabled,
      "variant": "medium",
      "size": "sm"
    },
    on: {
      "click": _vm.deletePackage
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'trash'],
      "fixed-width": ""
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex flex-wrap ml-1 px-3 py-3"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('CampaignItemPackageProduct', {
      key: index,
      attrs: {
        "campaign-item": item,
        "disabled": _vm.disabled
      },
      on: {
        "preview": function ($event) {
          var _vm$campaignPackage;

          return _vm.$emit('preview', {
            subTitle: (((_vm$campaignPackage = _vm.campaignPackage) === null || _vm$campaignPackage === void 0 ? void 0 : _vm$campaignPackage.name) + ": " + ($event.product.name)),
            title: $event.mediaObject.name,
            imageSrc: $event.mediaObject.previewUrl
          });
        },
        "browse": function ($event) {
          return _vm.$emit('browse', item['@id']);
        },
        "reset": function ($event) {
          return _vm.$emit('reset', item['@id']);
        },
        "upload": function ($event) {
          return _vm.$emit('upload', item['@id']);
        }
      }
    });
  }), 1), _c('MediaObjectGallery', {
    ref: "gallery"
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }