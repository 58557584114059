var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "updateFile",
      "title": "Update een File",
      "centered": "",
      "scrollable": "",
      "header-bg-variant": "secondary",
      "header-text-variant": "light"
    },
    on: {
      "ok": _vm.update
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Naam"
    }
  }, [_c('b-form-input', {
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submit();
      }
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tags"
    }
  }, [_vm._l(_vm.tags.length, function (n) {
    return _c('div', {
      key: n,
      staticClass: "d-flex align-items-center mb-1"
    }, [_c('b-form-input', {
      staticClass: "mr-1",
      attrs: {
        "variant": "info"
      },
      on: {
        "keyup": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
          return _vm.submit();
        }
      },
      model: {
        value: _vm.tags[n - 1],
        callback: function ($$v) {
          _vm.$set(_vm.tags, n - 1, $$v);
        },
        expression: "tags[n - 1]"
      }
    }), _c('b-btn', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function ($event) {
          return _vm.tags.splice(n - 1, 1);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": ['fal', 'minus']
      }
    })], 1)], 1);
  }), _c('b-btn', {
    on: {
      "click": function ($event) {
        return _vm.tags.push('');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['far', 'plus']
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }